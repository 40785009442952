<template>
  <div
    id="login"
    :style="{
      'background-position': 'center center',
      'background-image': 'url(' + backgroundImg(advert.image) + ')',
      height: '604px',
    }"
  >
    <div class="wrap">
      <!-- <img :src="advert.image | fullPath" alt=""> -->
      <div class="login_item">
        <div class="title">会员登录</div>
        <div class="inputs">
          <img src="@/assets/img/login-01.png" alt="" />
          <input
            @keyup.enter="submit"
            v-model="form.username"
            type="text"
            placeholder="手机"
          />
        </div>
        <div class="inputs">
          <img src="@/assets/img/login-03.png" alt="" />
          <input
            @keyup.enter="submit"
            v-model="form.password"
            type="password"
            placeholder="密码"
          />
        </div>
        <div class="inputs" style="display: flex">
          <img src="@/assets/img/login-03.png" alt="" />
          <input @keyup.enter="submit" v-model="form.code" placeholder="验证码" />
          <div style="width: 40%">
            <img
              :src="images"
              @click="getAdvertList"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
        <!-- <div class="inputs">
					<i class="icon-font el-icon-picture"></i>
					<input type="text" @keyup.enter="submit" v-model="verifyCode" placeholder="图片验证码" maxlength="6">
                    <div class="imgCodeMode" @click="getVerifyCode" v-loading="verifysignLoding"><img :src="codeUrl" alt=""></div>
				</div> -->
        <div class="btn" @click="handleLogin">登 录</div>
        <div class="other">
          <router-link to="/forget_password">忘记密码</router-link>
          <router-link to="/register">注册账号</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rsa from "@/utils/rsa";
import { getCaptchaImage } from "@/api/other/index.js";
export default {
  name: "login",
  data() {
    return {
      form: {},
      images: "",
      phone: "",
      password: "",
      codeUrl: "",
      // verifyCode: '',
      // verifysign: '',
      // verifysignLoding: false,
      loading: false, //防重复点击
      advert: {},
      redirect: undefined,
    };
  },
  created() {
    // this.getVerifyCode();
    this.getAdvertList();
    // // this.IsPC();
  },
  mounted() {},
  watch: {
    $route: {
      handler(route) {
        // console.log(route);
        this.redirect = (route.query && route.query.redirect) || "/";
      },
      immediate: true,
    },
  },
  methods: {
    backgroundImg(value) {
      var host = document.location.host;
      var result = "";
      if (value && value.indexOf("files") != -1) {
        if (host.search("192") != -1) {
          result = "http://192.168.0.172:8080/" + value;
        } else {
          result = document.location.protocol + "//" + host + "/" + value;
        }
      } else {
        result = value;
      }
      return result;
    },
    submit() {
      if (this.loading) return;
      this.loading = true;
      const loading = this.$loading();
      //consloe.log(this.phone);
      //consloe.log(this.password);
      // let reg = 11 && /^[1][0-9][0-9]{9}$/;
      // if (!reg.test(this.phone)) {
      //   this.$message.warning("请输入正确的手机号码");
      //   this.loading = false;
      //   loading.close();
      //   return;
      // }
      if (this.password == "") {
        this.$message.warning("请输入密码");
        this.loading = false;
        loading.close();
        return;
      }
      // if(this.verifyCode == ''){
      // 	this.$message.warning('请输入图片验证码')
      // 	this.loading = false;
      // 	loading.close();
      // 	return
      // }
      this.$request.post({
        url: "/ReportApi/business/login",
        params: {
          account: this.phone,
          password: this.password,
          // verifyCode: this.verifyCode,
          // verifySign: this.verifysign
        },
        success: (res) => {
          var arr = {};
          delete res.email;
          delete res.first_recharge_time;
          delete res.openid;
          delete res.parent_id;
          delete res.password;
          delete res.remark;

          delete res.total_amount;

          res.createTime = res.create_time;
          delete res.create_time;
          res.firstRechargeStatus = res.first_recharge_status;
          delete res.first_recharge_status;
          res.inviteCount = res.invite_count;
          delete res.invite_count;
          res.levelId = res.level_id;
          delete res.level_id;
          res.withdrawStatus = res.withdraw_status;
          delete res.withdraw_status;
          arr.info = res;
          if (res.levelId == 0) {
            arr.level = "普通会员";
          } else if (res.levelId == 1) {
            arr.level = "高级会员";
          } else {
            arr.level = "最高会员";
          }

          //consloe.log(arr.info);

          localStorage.setItem(
            "dftToken",
            "Bearer_eyJhbGciOiJIUzUxMiJ9.eyJleHAiOjE2MzI0NzIwOTEsInN1YiI6IjE4MDMwMzg3MDg4Uk9MRV9CVVMiLCJjcmVhdGVkIjoxNjMyMjk5MjkxMjQyfQ.rrykxo10lVJWTjLfhBhAw3sgl9OqNxNpSayA9CojLVnuAPo5JoCbAZOeDIcCUNHLEoxR9JdKW9SMHHZRTbulDQ"
          );

          localStorage.setItem("phone", arr.info.phone);
          localStorage.setItem("ids", arr.info.id);
          this.$store.commit("getUserInfo", arr);
          this.$store.commit("setIsLogin", true);
          this.$router.push("/member/index");
        },
        error: (e) => {
          // this.getVerifyCode()
        },
        finally: () => {
          this.loading = false;
          loading.close();
        },
      });
    },
    // getVerifyCode(){
    //     this.verifysignLoding = true
    //     this.$axios.get('/public/verifyCode?' + Math.random(),{responseType: 'arraybuffer'}).then(res => {
    //         let codeImg = 'data:image/png;base64,' + btoa(new Uint8Array(res.data).reduce((data,byte) => data + String.fromCharCode(byte), ''))
    //         this.codeUrl = codeImg
    //         this.verifysign = res.headers.sign
    //         this.verifysignLoding = false
    //     })
    // },
    getAdvertList() {
      getCaptchaImage().then((res) => {
        if (res.code == 200) {
          this.images = "data:image/gif;base64," + res.data.img;
          console.log(this.images);
          this.form.uuid = res.data.uuid;
          localStorage.setItem("uuid", res.data.uuid);
        }
      });
    },
    handleLogin() {
      this.loading = true;
      this.$store
        .dispatch("user/login", this.form)
        .then(async () => {
          localStorage.setItem("loginAccount", this.form.username);
          this.Flag = localStorage.getItem("flag");
          console.log(this.redirect)
          let routerPath =
            // this.redirect === "/404" || this.redirect === "/401" ? "/" : this.redirect;
          this.$router.push('/IndexThree').catch(() => {});
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#login {
  background: #3c92ff;
  .wrap {
    position: relative;
    img {
      display: block;
    }
  }
  .login_item {
    position: absolute;
    width: 350px;
    right: 40px;
    top: 48px;
    background: #fff;
    padding: 30px;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 20px;
      color: #8c8c8c;
      line-height: 40px;
      margin-bottom: 30px;
    }
    .inputs {
      position: relative;
      margin-bottom: 15px;
      border: 1px solid #e1e1e1;
      height: 36px;
      input {
        border: none;
        color: #666;
        outline: none;
        height: 36px;
        font-size: 16px;
        width: 240px;
        vertical-align: bottom;
      }
      img {
        width: 25px;
        display: inline-block;
        margin: 0 5px;
      }
      .icon-font {
        font-size: 18px;
        margin: 8px;
        color: #cacaca;
      }
      .imgCodeMode {
        height: 36px;
        position: absolute;
        top: 0;
        right: 0;
        img {
          margin: 0;
          height: 100%;
          width: auto;
          display: block;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .btn {
      width: 100%;
      line-height: 38px;
      background: #f80;
      color: #fff;
      text-align: center;
      border-radius: 4px;
      &:hover {
        cursor: pointer;
      }
    }
    .other {
      margin-top: 10px;
      font-size: 12px;
      color: #666;
      a {
        &:last-child {
          padding-left: 5px;
        }
        &:hover {
          cursor: pointer;
          color: #ff464e;
        }
      }
    }
  }
}
</style>
